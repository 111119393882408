/** @format */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectChain from "./SelectChain";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";
import slimeShop from "../../../../../assets/images/teleImage/TitleQuest.png";
import DailyCheckin7 from "../../../../../assets/images/teleImage/DailyCheckin7.png";
import Reward10 from "../../../../../assets/images/teleImage/Reward10.png";
import Reward5 from "../../../../../assets/images/common/Reward5.png";
import Reward20 from "../../../../../assets/images/teleImage/Reward20.png";
import Reward30 from "../../../../../assets/images/teleImage/Reward30.png";
import Reward30s from "../../../../../assets/images/teleImage/Reward30s.png";
import Reward30Inside from "../../../../../assets/images/teleImage/reward30inside.png";
import ComingSoon from "../../../../../assets/images/tokens/Comingsoon.png";
import questsImg from "../../../../../assets/images/teleImage/quests.png";
import enableBtn from "../../../../../assets/images/teleImage/SRIconArrow.png";
import disableBtn from "../../../../../assets/images/teleImage/disableBtn.png";
import dividerImg from "../../../../../assets/images/teleImage/dividerImg.png";
import doneBtn from "../../../../../assets/images/tokens/Check_icon.png";
import energyPlus from "../../../../../assets/images/teleImage/energyPlus.png";
import Slime from "../../../../../assets/roll/Slime.svg";
import checkinedImg from "../../../../../assets/images/teleImage/checkinImg.png";
import Reward from "../../../../../assets/images/teleImage/Reward.png";
import dailycheckin from "../../../../../assets/images/teleImage/dailycheckin.png";
import DailyCheckinBanner from "../../../../../assets/images/teleImage/DailyCheckinBanner.png";
import {
	getQuestFromJSON,
	QuestDataRes,
} from "../../../../../Modal/Quest/userQuest";
import {
	getUserInforFromJSON,
	UserInforData,
} from "../../../../../Modal/User/userInfor";
import { decodeJwt, encodeJwt } from "../../../../../utils/extension";
import {
	apiGetMyAccount,
	checkedInRequest,
	checkInToday,
	telegramConfirm,
	telegramUser,
	updateMyAccount,
} from "../../../../../services/accountApiService";
import LoadingCommon from "../../../../../commonsUi/CommonUI/LoadingApp";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import CircleButton from "../../../../../commonsUi/commonButton/gameButton/CircleButton/CircleButton";
import AptosWalletConnect from "../../walletConnect";
import {
	InputTransactionData,
	useWallet,
} from "@aptos-labs/wallet-adapter-react";
import { aptos, MODULE_ADDRESS } from "../../../../../aptosConfig";
import { getRandomString } from "../../../../../utils/constants/pages";
import { parseEther } from "viem";
import {
	AccountAddress,
	Ed25519PrivateKey,
	InputEntryFunctionData,
	Secp256k1PrivateKey,
	TransactionPayload,
} from "@aptos-labs/ts-sdk";
import { AptosAccount } from "aptos";
import { Account } from "@aptos-labs/ts-sdk";
import { stavaxAccount } from "../../../../../connectStavax";
import {
	CHECKIN_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../../../ABI/checkinDaily";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import Countdown from "react-countdown";
import { IQuestSelect } from "../../../../../lib/types/quest";

export const requestCheckedIn = async (address: any) => {
	try {
		const value = {
			address_smart_wallet: address,
		};
		const payLoadData = await encodeJwt(value);
		const res = await checkedInRequest({
			value: payLoadData + getRandomString(10),
		});
	} catch (e) {}
};

const QuestPage: React.FC = () => {
	const accountWalletInfo = useSelector((state: any) => state.accountWallet);
	const dispatch = useDispatch();
	const [isClaim, setClaim] = useState(false);
	const [questData, setQuestData] = useState<QuestDataRes[]>([]);
	const [inviteFriendItem, setInviteFriendItem] =
		useState<QuestDataRes | null>(null);
	const [dailyQuestItem, setDailyQuestItem] = useState<QuestDataRes[]>([]);
	const account = useAccount();
	const [isShowLoading, setShowLoading] = useState(false);
	const [isShowLoading1, setShowLoading1] = useState(false);
	const [questSelected, setQuestSelected] = useState<QuestDataRes | null>(
		null
	);
	const publicClient = usePublicClient();
	const { switchChainAsync } = useSwitchChain();
	const { writeContractAsync } = useWriteContract();
	const [isShowInviteFriend, setShowInviteFriend] = useState(false);
	const [indexQuestSelected, setIndexQuestSelected] = useState(-1);
	const [showCheckIn, setShowCheckIn] = useState(false);
	const [isCheckedIn, setCheckIn] = useState<string | null>(null);
	const [inforData, setInfoData] = useState<UserInforData | null>(null);
	const shareRef = useRef<HTMLParagraphElement>(null);
	const [isShowConnectWalelt, setShowConnectWallet] = useState(false);
	const [listQuestSuccess, setListQuestSuccess] = useState<
		string[] | undefined
	>([]);
	// const {
	// 	wallets,
	// 	connect,
	// 	account,
	// 	disconnect,
	// 	signAndSubmitTransaction,
	// 	signTransaction,
	// 	signMessage,
	// } = useWallet();
	const accountWagmi = useAccount();

	// const handleVisibilityChange = () => {
	// 	if (document.visibilityState === "visible") {
	// 		hasCheckedInToday();
	// 	}
	// };

	// useEffect(() => {
	// 	document.addEventListener("visibilitychange", handleVisibilityChange);
	// 	return () => {
	// 		document.removeEventListener(
	// 			"visibilitychange",
	// 			handleVisibilityChange
	// 		);
	// 	};
	// }, []);

	const dailyCheckIn = async () => {
		if (accountWagmi?.address === undefined) {
			const session = await stavaxAccount.connect();
			console.log({ session });
			const botURL = stavaxAccount.getTgBotWebAppURL(session!).value;
			console.log({ botURL });
		} else {
			try {
				// const isWrongChain = accountWagmi?.chainId !== 8453;
				// if (isWrongChain) {
				// 	await switchChainAsync({ chainId: 8453 });
				// }
				// stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: CHECKIN_CONTRACT_ADDRESS_BASE,
					functionName: "mint",
					value: parseEther("0"),
				});
				setClaim(true);
				setCheckIn("Check in successfully");
				requestCheckedIn(account?.address);
				publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
			} catch (e: any) {
				console.log({ e });
				setClaim(false);
				if (e.details === "User cancel") {
					setCheckIn("You have declined the transaction");
				} else {
					setCheckIn(
						e.message +
							"/n" +
							e.details +
							`connect Status: ${accountWagmi.isConnected} ---- ${accountWagmi.status}`
					);
				}
			}
		}
	};

	const hasCheckedInToday = async () => {
		if (!account) return [];
		try {
			const isCheckedIn: any = await aptos.view({
				payload: {
					function: `${MODULE_ADDRESS}::CheckInDaiLySlime::get_last_checkin_time`,
					typeArguments: [],
					functionArguments: [account.address],
				},
			});
			console.log({ isCheckedIn });
			if (isCheckedIn[0]) {
				const timestamp = isCheckedIn[0] * 1000;
				const date = new Date(timestamp);
				const now = new Date();
				const diffInMinutes =
					(now.getTime() - date.getTime()) / 1000 / 60;
				if (Math.abs(diffInMinutes) <= 30) {
					setClaim(true);
					setCheckIn("Check in successfully");
					requestCheckedIn(account?.address);
				}
			} else {
				setCheckIn("Check in failure");
			}
		} catch (e: any) {
			console.log({ e });
		}
	};
	const requestUpdateProfile = async (walletAddress: string) => {
		try {
			const value = {
				address_smart_wallet: walletAddress,
			};
			const payLoadData = await encodeJwt(value);
			await updateMyAccount({ value: payLoadData });
		} catch (e) {
			console.log({ e });
		}
	};

	useEffect(() => {
		if (account?.address !== undefined) {
			requestUpdateProfile(account.address);
		}
	}, [account]);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);

	const handleButtonClick = (url: string) => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			window.location.href = url;
		} else {
			window.open(url, "_blank", "noopener,noreferrer");
		}
	};

	const telegramUserRequest = async () => {
		try {
			const resTelegramUser = await telegramUser();
			const payload = await decodeJwt(resTelegramUser.data);
			const value = getQuestFromJSON(JSON.stringify(payload));

			setQuestData(
				value.data.list_user_quest
					.filter(
						(item) => item.quest_type === 0 && item.quest_id !== 7
					)
					.sort((a, b) => a.quest_status - b.quest_status)
			);
			setDailyQuestItem(
				value.data.list_user_quest.filter(
					(item) => item.quest_type === 1
				)
			);
			const inviteFriend = value.data.list_user_quest.find(
				(item) => item.quest_id === 7
			);
			if (inviteFriend) {
				setInviteFriendItem(inviteFriend);
			}
			console.log(value.data.list_user_quest);
		} catch (error) {
			console.error("Error fetching Telegram user:", error);
		}
	};

	const telegramConfirmRequest = async (id: number) => {
		try {
			const confirm = await telegramConfirm(id);
			await decodeJwt(confirm.data);
			await telegramUserRequest();
			setCheckIn("Please open your mailbox to claim the rewards");
		} catch (e) {}
	};
	const handleCallApiPillageJourneyInfoAgain = async () => {
		const responsePillageJourneyInfo = await apiGetMyAccount();
		const value = getUserInforFromJSON(
			JSON.stringify(responsePillageJourneyInfo)
		);
		if (value?.success) {
			setInfoData(value);
		}
	};
	const requestCheckInToday = async () => {
		try {
			const value = {
				address_smart_wallet: account?.address,
			};
			const payLoadData = await encodeJwt(value);
			const isCheckedInToday = await checkInToday({ value: payLoadData });
			const resDecode: any = await decodeJwt(isCheckedInToday?.data);
			setClaim(resDecode?.data?.status_check_in_day === 1);
		} catch (e) {}
	};

	const handleClickQuest = async (
		questSelected: IQuestSelect
	): Promise<void> => {
		const checkCondition: boolean = questSelected.quest_id === 6;
		if (!checkCondition) {
			localStorage.setItem(
				questSelected.quest_name!,
				questSelected.quest_name!
			);
			const timeAdd: number = new Date().getTime() + 3600000;
			localStorage.setItem(
				`${questSelected.quest_name}_time`,
				JSON.stringify(timeAdd)
			);

			setListQuestSuccess((prev) => (prev ? [...prev] : []));

			handleButtonClick(questSelected.quest_link_social!);
		}
	};

	const handleClickCheckStatusQuest = (questSelected: any) => {
		const getTimeReceiveQuest = localStorage.getItem(
			`${questSelected.quest_name}_time`
		);
		const checkTimeReceiveQuest = getTimeReceiveQuest
			? new Date().getTime() > Number(getTimeReceiveQuest)
			: false;
		if (
			localStorage.getItem(questSelected.quest_name ?? "") != null &&
			checkTimeReceiveQuest
		) {
			setQuestSelected(null);
			telegramConfirmRequest(questSelected.quest_id ?? 0);
		} else {
			setShowCheckIn(true);
		}
	};
	const handleTitleShowButton = (questSelected: IQuestSelect) => {
		const getTimeReceiveQuest = localStorage.getItem(
			`${questSelected.quest_name}_time`
		);
		const checkTimeReceiveQuest = getTimeReceiveQuest
			? new Date().getTime() > new Date(getTimeReceiveQuest).getTime()
			: false;
		return getTimeReceiveQuest &&
			!checkTimeReceiveQuest &&
			!listQuestSuccess?.includes(questSelected?.quest_name) ? (
			<Countdown
				date={Number(
					localStorage.getItem(`${questSelected.quest_name}_time`)
				)}
				onComplete={() => {
					setListQuestSuccess((prev) =>
						prev
							? [...prev, questSelected.quest_name]
							: [questSelected.quest_name]
					);
				}}
			/>
		) : (
			"Check Quest Status"
		);
	};
	useEffect(() => {
		requestCheckInToday();
		telegramUserRequest();
		handleCallApiPillageJourneyInfoAgain();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setCheckIn(null);
		}, 3000);
		return () => clearTimeout(timer);
	}, [isCheckedIn]);

	return (
		<div className={styles.main}>
			<img
				src={slimeShop}
				alt="slimeShop"
				style={{
					width: "250px",
					height: "54px",
					display: "flex",
					alignSelf: "center",
					justifySelf: "center",
				}}
			/>
			{/* <SelectChain /> */}
			<div className={styles.titleQuestStyle}>Daily Quest</div>
			<div
				id="button"
				className={styles.checkInStyle}
				onClick={() => {
					if (!isClaim) {
						dailyCheckIn();
					}
				}}
			>
				<img src={Reward10} alt="Reward" width={66} />
				<p className={styles.checkinTextStyle}>Check in On Chain</p>
				<img
					src={!isClaim ? enableBtn : doneBtn}
					alt="enableBtn"
					width={30}
				/>
			</div>

			{dailyQuestItem?.map((e, index) => (
				<div
					style={{
						marginTop: "10px",
					}}
					key={index}
					className={styles.checkInStyle}
					onClick={() => {
						if (dailyQuestItem[index]?.quest_status === 0) {
							setQuestSelected(e);
						}
					}}
				>
					<img
						src={
							e.quest_reward_energy === 10
								? Reward10
								: e.quest_reward_energy === 5
								? Reward5
								: Reward30
						}
						alt={Reward10}
						height={60}
					/>
					<p className={styles.checkinTextStyle}>{e.quest_name}</p>
					<img
						src={
							e.quest_status === 1
								? doneBtn
								: dailyQuestItem[index].quest_status === 2
								? ComingSoon
								: enableBtn
						}
						alt="statusBtn"
						width={30}
						className={"active-btn"}
					/>
				</div>
			))}
			{/* <div className={styles.titleQuestStyle}>{`Invite Friends ${
				inforData?.data?.invitedCount || 0
			}/5`}</div>
			<div
				className={styles.checkInStyle}
				onClick={() => {
					setShowInviteFriend(true);
					// questData?.find((item) => item.quest_id === 7)?.quest_status === 0
					// 	? setShowInviteFriend(true)
					// 	: null;
				}}
			>
				<img src={Reward20} alt="Reward" width={66} />
				<div>
					<p className={styles.checkinTextStyle}>Invite Friend </p>
					<p className={styles.checkInDesStyle}>(+20 Energy each)</p>
				</div>
				<img
					src={
						inforData && inforData!.data?.invitedCount >= 5
							? doneBtn
							: enableBtn
					}
					alt="enableBtn"
					width={30}
				/>
			</div> */}

			{/* <img
            src={DailyCheckin7}
            alt="DailyCheckin7"
            style={{
                width: "130px",
                height: "30px",
                marginLeft: "20px",
                marginTop: "20px",
            }}
        /> */}
			{/* <DailySevenChecking /> */}
			<div className={styles.titleQuestStyle}>Quest</div>
			<div className={styles.body}>
				{questData?.map((e, index) => (
					<div
						id="button"
						key={index}
						onClick={() => {
							if (questData[index]?.quest_status === 0) {
								setIndexQuestSelected(index);
								setQuestSelected(e);
							}
						}}
					>
						<>
							<div className={styles.itemStyles}>
								<img
									src={
										e.quest_reward_energy === 10
											? Reward10
											: e.quest_reward_energy === 5
											? Reward5
											: Reward30
									}
									alt={e.quest_reward_energy.toString()}
									height={60}
								/>
								<p
									className={
										e.quest_status === 1 ||
										e.quest_status === 0
											? styles.itemTextStyle
											: styles.itemTextStyleGray
									}
								>
									{e.quest_name}
								</p>
								<div
									style={{
										width: "60px",
									}}
								>
									<img
										src={
											questData[index]?.quest_status === 1
												? doneBtn
												: questData[index]
														.quest_status === 2
												? ComingSoon
												: enableBtn
										}
										alt="statusBtn"
										width={30}
										className={"active-btn"}
									/>
								</div>
							</div>
							<img
								src={dividerImg}
								alt="dividerImg"
								height={5}
								style={{
									width: "80vw",
									height: "5",
									marginLeft: "16px",
								}}
							/>
						</>
					</div>
				))}
			</div>
			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>
			{showCheckIn && (
				<div
					className={styles.notice1}
				>{`You haven't completed this quest.`}</div>
			)}
			{isCheckedIn != null && (
				<div className={styles.checkedInStyle}>{`${isCheckedIn}`}</div>
			)}
			{questSelected != null && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setQuestSelected(null)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							{questSelected?.quest_name}
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: "80vw",
								justifyContent: "center",
							}}
						>
							<img
								src={
									questSelected.quest_reward_energy === 10
										? Reward10
										: questSelected.quest_reward_energy ===
										  5
										? Reward5
										: Reward30
								}
								alt="energyPlus"
								height={95}
							/>
						</div>
						<CircleButton
							title={
								questSelected.quest_id === 6
									? "Connect now"
									: "Follow"
							}
							onTap={() => handleClickQuest(questSelected)}
						/>
						<CircleButton
							isGrayColor={true}
							title={handleTitleShowButton(questSelected)}
							onTap={() =>
								handleClickCheckStatusQuest(questSelected)
							}
						/>
					</div>
				</ChoosePlanFarming>
			)}
			<RWebShare
				data={{
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: `https://t.me/Slime_Revolution_bot?start=${localStorage.getItem(
						"_userId"
					)}`,
				}}
				onClick={() => console.log("shared successfully!")}
			>
				<p ref={shareRef}></p>
			</RWebShare>
			{isShowInviteFriend && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setShowInviteFriend(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Invite Friend to earn more Energy
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: "80vw",
								justifyContent: "center",
							}}
						>
							<img src={Reward20} alt="energyPlus" height={80} />
						</div>
						<CircleButton
							title="Invite now"
							onTap={async () => {
								setShowInviteFriend(false);
								if (shareRef.current) {
									shareRef.current.click();
								}
							}}
						/>

						<CircleButton
							isGrayColor={true}
							title={`Check quest status ${
								inforData?.data?.invitedCount || 0
							}/5`}
							onTap={() => {
								if (inforData?.data?.invitedCount! >= 5) {
									telegramConfirmRequest(7);
									setShowInviteFriend(false);
								} else {
									setShowCheckIn(true);
								}
							}}
						/>
					</div>
				</ChoosePlanFarming>
			)}
			{isShowConnectWalelt && (
				<AptosWalletConnect
					onClose={() => setShowConnectWallet(false)}
				/>
			)}
		</div>
	);
};

export default QuestPage;
