/** @format */

import React, { useEffect} from "react";
type IsLandComponentType = {
	visible: boolean;
	setIsClient: React.Dispatch<React.SetStateAction<boolean>>;
	// other props...
};

export default function IsLandComponent({
	visible,
	setIsClient,
}: IsLandComponentType) {
	useEffect(() => {
		// setIsClient(true);
	}, []);
	return (
		<div style={{ maxHeight: "calc(100vh - 76px)", marginBottom: "76px" }}>
            <p>{}</p>
			{localStorage.getItem("_gameAccessToken") != null && (
				<iframe
				    title="Island Game"
					id="isLandIframe"
					style={{
						width: "100%",
						height: "calc(100vh - 76px)",
					}}
					src={`${
						process.env.REACT_APP_ISLANDS_LINK
					}?access_token=${localStorage.getItem(
						"_gameAccessToken"
					)}&user_id=${localStorage.getItem("_userId")}?time=1`}
				></iframe>
			)}
		</div>
	);
}
