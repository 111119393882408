/** @format */

import ChoosePlanFarming from "../choosePlanFarming";
import style from "./index.module.scss";
import iconUSDT from "../../../assets/roll/modals/USDT.png";
import {
	changeStatusWithDraw,
	withDrawUsd,
} from "../../../services/accountApiService";
import { decodeJwt, encodeJwt } from "../../../utils/extension";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { stavaxAccount } from "../../../connectStavax";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";

type ConnectWalletType = {
	onClosePopup: () => void;
};

export default function ModalConnectWallet({
	onClosePopup,
}: ConnectWalletType) {
	const account = useAccount();
	const { switchChainAsync } = useSwitchChain();

	const connectWaletSTV = async () => {
		if (account?.address === undefined) {
			const session = await stavaxAccount.connect();
			const botURL = stavaxAccount.getTgBotWebAppURL(session!).value;
			console.log({ botURL });
		} else {
			try {
				const isWrongChain = account?.chainId !== 8453;
				if (isWrongChain) {
					await switchChainAsync({ chainId: 8453 });
				}
				stavaxAccount.openTgBot();
				onClosePopup();
			} catch (e) {
				console.log({ e });
			}
		}
	};

	return (
		<ChoosePlanFarming
			onClose={onClosePopup}
			iconPopup={iconUSDT}
			customStyle={{
				width: "230px",
				height: "auto",
				top: "-30%",
			}}
		>
			<div className={style.popup__usdt_wrapper}>
				<h2 className={style.popup__usdt_title}>
					{"Connect wallet to save your USDT"}
				</h2>
				<div className={style.popup__usdt_list_button}>
					<div>
						<div
							style={{
								marginTop: "50px",
							}}
						>
							<CircleButton
								isGrayColor={false}
								title="Connect now"
								onTap={() => {
									console.log("ádf");
									connectWaletSTV();
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</ChoosePlanFarming>
	);
}
