/** @format */
import * as jose from 'jose';
import { JWT_SIGNING } from '../constants';
export const encodeJwt = async (data: any) => {
	const enc = new TextEncoder();
	const secret = enc.encode(JWT_SIGNING);
	return await new jose.SignJWT(data).setProtectedHeader({ alg: 'HS256' }).sign(secret);
};

export const decodeJwt = async (token: string) => {
	try {
		const enc = new TextEncoder();
		const secret = enc.encode(JWT_SIGNING);
		const { payload } = await jose.jwtVerify(token, secret);
		return payload;
	} catch (e) {
		return;
	}
};
export const decodeJwtWallet = async (token: string) => {
	try {
		const enc = new TextEncoder();
		const secret = enc.encode(JWT_SIGNING);
		const { payload } = await jose.jwtVerify<jose.JWTPayload>(token, secret);
		return payload;
	} catch (e) {
		return;
	}
};

export const decodeBase64 = (base64String: string) => {
	try {
		if (!base64String) {
			return;
		}
		const decodedString = Buffer.from(
			base64String?.replace('data:application/json;base64,', ''),
			'base64'
		).toString('utf-8');

		const decodedJSON = JSON.parse(decodedString);
		return decodedJSON;
	} catch (error) {
		return null;
	}
};
