/** @format */

import styles from "./style.module.scss";
import logoapp from "../../../../../assets/images/teleImage/iconSlime.png";
import metamask from "../../../../../assets/images/teleImage/metamask.png";
import coinbase from "../../../../../assets/images/teleImage/coinbase.png";
import iconUSDT from "../../../../../assets/images/teleImage/USDT.png";
import icon10000 from "../../../../../assets/images/teleImage/10000.png";
import iconBaseLogo from "../../../../../assets/images/teleImage/base_logo.png";

import { useConnect, useAccount } from "wagmi";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { IRenderPopupPage } from "../../../../../lib/types/modal";
import { ReactNode } from "react";
const LoadingGame = ({ walletType }: any) => {
	const { connect, connectors } = useConnect();
	const location = useLocation();
	const YourComponent = () => {
		return (
			<div className={styles.container}>
				<div>{RenderPopupPage(location?.pathname)}</div>
				<button
					className={styles.connectCoinbaseButton}
					id="button"
					onClick={() => {
						("Coinbase");
						connect({ connector: connectors[3] });
						localStorage.setItem("wallet_type", "Coinbase");
					}}
				>
					{/* <img src={coinbase} alt="Coinbase Logo" /> */}
					Play now
				</button>
				<div className={styles.baseLogo}>
					<p>Power by</p>
					<img src={iconBaseLogo} alt="base logo" />
				</div>
				{/* <button
					className={styles.connectMetamaskButton}
					onClick={() => {
						localStorage.setItem("wallet_type", "Metamask");
						connect({ connector: connectors[1] });
					}}
					id="button"
				>
					<img src={metamask} alt="Coinbase Logo" />
					Connect Metamask
				</button> */}
				<div className={styles.aboutStyle}>
					<a
						href="https://t.me/SlimeRoyale"
						target="_blank"
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						rel="noopener noreferrer"
					>
						<FaTelegramPlane size={16} color="black" />
						<span
							style={{
								paddingTop: "1px",
							}}
						>{` Join Telegram`}</span>
					</a>
					<a
						href="https://x.com/SlimeRoyale/"
						target="_blank"
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						rel="noopener noreferrer"
					>
						<FaTwitter size={16} color="black" />
						<span
							style={{
								paddingTop: "1px",
							}}
						>{` Follow us on Twitter`}</span>
					</a>
					<a
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						href="https://about.slimerevolution.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaBook size={16} color="black" />
						<span
							style={{
								paddingTop: "1px",
							}}
						>{` About Slime Revolution`}</span>
					</a>
				</div>
				<div>
					{location.pathname === "/home1" && (
						<p className={styles.title__slime}>Slime Revolution</p>
					)}
				</div>
				{/* <div className={styles.iconList}>
                    <div className={styles.iconItem}>
                        <a
                            href="https://docs.frenpet.com"
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className={styles.icon}>📄</span>
                            Read the FrenPet Docs
                        </a>
                    </div>
                    <div className={styles.iconItem}>
                        <a
                            href="https://discord.com/invite/frenpet"
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className={styles.icon}>💬</span>
                            Join Discord
                        </a>
                    </div>
                    <div className={styles.iconItem}>
                        <a
                            href="https://twitter.com/frenpet"
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className={styles.icon}>🐦</span>
                            Follow us on Twitter
                        </a>
                    </div>
                    <div className={styles.iconItem}>
                        <a
                            href="https://basescan.org"
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className={styles.icon}>🔍</span>
                            Basescan
                        </a>
                    </div>
                </div> */}
			</div>
		);
	};

	return (
		<div className={`${styles.loading__game}`}>
			<div className={styles.loadingContainer}>
				<YourComponent />
			</div>
		</div>
	);
};

export default LoadingGame;

const RenderPopupPage = (pathName: string): ReactNode => {
	const dataComponentPath: { [key: string]: ReactNode } = {
		"/home1": <HomeOneComponent />,
		"/home2": <HomeTwoComponent />,
	};
	return dataComponentPath[pathName] || <DefaultComponent />;
};

const HomeOneComponent = () => {
	return (
		<div className={styles.home__one}>
			<h2>Spin for USDT</h2>
			<img alt="icon usdc" src={iconUSDT} width={70} />
			<p>
				Slime Revolution is a blockchain-based social game that merges
				gaming excitement with cryptocurrency rewards
			</p>
		</div>
	);
};

const HomeTwoComponent = () => {
	return (
		<div className={styles.home__two}>
			<h2>Spin for USDT</h2>
			<div className={styles.home__two__price}>
				<img alt="icon 10000" src={icon10000} width={200} height={53} />
				<img alt="icon usdc" src={iconUSDT} width={70} />
			</div>
			<p>
				A total rewards pool of $10,000 awaits you! Event starts August
				8 until all rewards are claimed.
			</p>
		</div>
	);
};

const DefaultComponent = () => {
	return (
		<>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					columnGap: "10px",
					fontFamily: "Poppins",
					justifyContent: "center",
				}}
			>
				<img src={logoapp} alt="Logo" className={styles.logo} />
				<h1 className={styles.title}>Slime Revolution</h1>
			</div>
			<p className={styles.subtitle}>
				{`Slime Revolution is a blockchain-based social game that merges gaming excitement with cryptocurrency rewards`}
			</p>
		</>
	);
};
