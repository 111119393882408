import { useState } from "react";
import React from "react";
import { useAccount } from "wagmi";

import TeleAppPage from "./TeleAppPage/index";
import LoadingGame from "./TeleAppPage/components/loadingGame";
import styles from "./RollItemPage.module.scss";

export default function Spin() {
	const account = useAccount();
	const [walletType, setWalletType] = useState();

	return (
		<div className={styles.main1}>
			{account?.address === undefined ? (
				<LoadingGame
					walletType={(value: React.SetStateAction<undefined>) => {
						console.log(value);
						setWalletType(value);
					}}
				/>
			) : (
				<TeleAppPage walletType={walletType} />
			)}
		</div>
	);
}
