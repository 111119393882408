import {
	useWallet,
	WalletName,
	InputTransactionData,
} from "@aptos-labs/wallet-adapter-react";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import { AptosClient, TxnBuilderTypes } from "aptos";

export const NODE_URL = "https://fullnode.testnet.aptoslabs.com/v1";
export const client = new AptosClient(NODE_URL);
export const MODULE_ADDRESS =
	"0xe012822f44b2765789a8b50cded4d71874a264d55b28eb61cfbe38abb1c6dd8b";
export const aptosConfig = new AptosConfig({ network: Network.MAINNET });
export const aptos = new Aptos(aptosConfig);
export const APTOS_COIN = "0x1::aptos_coin::AptosCoin";
export const COIN_STORE = `0x1::coin::CoinStore<${APTOS_COIN}>`;
