/** @format */

import React, { useEffect, useState } from "react";
import slimeShop from "../../../../../assets/images/teleImage/slimeShop.png";
import DiscountImag from "../../../../../assets/images/ranking/500energy.png";
import titleImage from "../../../../../assets/images/teleImage/title.png";
import styles from "./teleEnergyStyle.module.scss";
import energyIMG from "../../../../../assets/images/teleImage/400Energy.png";
import energyImg from "../../../../../assets/images/teleImage/energyImg.png";
import vfxImg from "../../../../../assets/images/teleImage/vfxImg.png";
import spcImg from "../../../../../assets/images/teleImage/usdtImg.png";
import ShopItemPage from "./ShopItemPage";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Slime from "../../../../../assets/roll/Slime.svg";
import { getShopPackageData } from "../../../../../services/accountApiService";
import { decodeJwt } from "../../../../../utils/extension";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import LoadingCommon from "../../../../../commonsUi/CommonUI/LoadingApp";
import GameButton from "../../../../../commonsUi/commonButton/gameButton/GameButton";
import { ShopItemModel } from "../../../../../commonsUi/modals/shop/shopModel";
import {
	useAccount,
	useBalance,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { stavaxAccount } from "../../../../../connectStavax";
import {
	SHOP_WALLET_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../../../ABI/shopWallet";
import { parseEther } from "viem";
import { writeClipboardText } from "../../../../../utils/function/text";
import { formatAddress } from "../Account";
import ModalCoppyAddress from "../../../../../commonsUi/modals/ModalCopyAddress";

export default function TeleEnergyShop() {
	const [isLoading, setLoading] = useState(true);
	const [dataShop, setDataShop] = useState<ShopItemModel[]>([]);
	const [ethUsdt, setEthUsdt] = useState(null);
	const [tokenPrice, setTokenPrice] = useState(null);
	const [multipliedPrice, setMultipliedPrice] = useState(null);
	const [dataHeader, setDataHeader] = useState<ShopItemModel | null>(null);
	const [isShowPayment, setShowPayment] = useState(false);
	const [isBought, setBought] = useState(false);
	const account = useAccount();
	const { writeContractAsync } = useWriteContract();
	const publicClient = usePublicClient();
	const [ethToUSDT, setEthToUsdt] = useState(0);
	const [insufficient, setInsufficient] = useState(false);
	const [showCheckIn, setShowCheckIn] = useState(false);
	const getShopPackageRequest = async () => {
		try {
			const res = await getShopPackageData();
			const resShopData: any = await decodeJwt(res.data);
			console.log({ resShopData });
			console.log({ resShopData });
			setDataShop(resShopData.data.slice(1));
			setDataHeader(
				resShopData.data.find((item: any) => item.Combo === 1)
			);
		} catch (e) {
			console.log({ e });
		}
	};
	const ethBalance = useBalance({
		address: account?.address,
	});

	const handleTransfer = async (id: number, amount: string) => {
		if (account?.address === undefined) {
			const session = await stavaxAccount.connect();
		} else {
			try {
				stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: SHOP_WALLET_CONTRACT_ADDRESS_BASE,
					functionName: "deposit",
					value: parseEther(amount),
					args: [id],
				});
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
				console.log({ hash });
				setShowPayment(false);
				setBought(true);
				setTimeout(() => {
					getShopPackageRequest();
				}, 2000);
			} catch (e) {
				console.log({ e });
			}
		}
	};

	const buyItem = async (id: number, usdtPrice: number) => {
		try {
			const bnbUsdtPriceResponse = await fetch(
				"https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT"
			);
			const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
			const value =
				Number(ethBalance?.data?.formatted) * bnbUsdtPriceData.price;
			if (value > usdtPrice) {
				handleTransfer(
					id,
					(usdtPrice / bnbUsdtPriceData.price).toString()
				);
			} else {
				setShowPayment(false);
				setInsufficient(true);
			}
		} catch (error) {
			console.error("Error fetching BNB/USDT price:", error);
		}
	};

	interface RenderDiscountWidgetProps {
		discountDt: number;
	}

	const RenderDiscountWidget: React.FC<RenderDiscountWidgetProps> = ({
		discountDt,
	}) => {
		return (
			<div className={styles.discountWater}>
				<p className={styles.discountTextStyle}>
					<span className={styles.extraSpace}>{`X5`}</span>
					<br />
					VALUE
				</p>
			</div>
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			await getShopPackageRequest();
			setLoading(false);
		};

		fetchData();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setBought(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isBought]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);
	// useEffect(() => {
	//     if (tokenPrice !== null && bnbUsdtPrice !== null) {
	//         const multipliedValue = tokenPrice * bnbUsdtPrice;
	//         setMultipliedPrice(multipliedValue);
	//     }
	// }, [tokenPrice, bnbUsdtPrice]);

	return (
		<div className={styles.body}>
			<img
				src={slimeShop}
				alt="slimeShop"
				style={{
					width: "250px",
					height: "54px",
					margin: "0px",
				}}
			/>
			{dataHeader && dataHeader!.BuyFirst && (
				<div
					className={styles.discountBgrStyle}
					onClick={() => setShowPayment(true)}
				>
					<div className={styles.titleBoxStyle}>
						30-day Spin Energy Boost
					</div>
					<div className={styles.disCountBodyStyle}>
						<div className={styles.leftDiscountStyle}>
							{/* <img
								src={vfxImg}
								alt="itemImage"
								height={100}
								width={130}
								className={styles.energyImagetyle}
							/> */}
							<img
								src={DiscountImag}
								alt="itemImage"
								height={120}
								width={130}
								className={styles.energyImagetyle}
							/>{" "}
							{/* <img
								src={energyIMG}
								width={50}
								alt="energyIMG"
								className={styles.energyPlusStyle}
							/> */}
						</div>
						<div className={styles.rightDiscountStyle}>
							<span className={styles.plusStyleText}>+50</span>
							<img
								src={energyImg}
								alt="energyImg"
								width={10}
								height={14}
							/>
							<span className={styles.plusStyleTextFirstDay}>
								{" "}
								First day
							</span>
							<br />
							<span className={styles.plusStyleText}>+15</span>
							<img
								src={energyImg}
								alt="energyImg"
								width={10}
								height={14}
							/>
							<span className={styles.plusStyleText}>day</span>
							<span className={styles.plusStyleTextFirstDay}>
								{" "}
								in 30 days
							</span>
							<p className={styles.oneTimeStyle}>
								0ne time/month/account
							</p>
						</div>
					</div>
					<div className={styles.bottomDiscountStyle}>
						<p className={styles.discountTextStyle1}>$9.99</p>
						<p
							className={styles.bottomDiscountTextStyle}
						>{`${dataHeader?.Price}$`}</p>
					</div>
					<RenderDiscountWidget discountDt={75} />
				</div>
			)}
			<img
				src={titleImage}
				alt="titleImage"
				style={{
					width: "360px",
					height: "50px",
					margin: "0px",
				}}
			/>
			{!isLoading ? (
				<ShopItemPage dataShop={dataShop} />
			) : (
				<LoadingCommon width={80} height={80} />
			)}
			{isBought && (
				<div
					className={styles.checkedInStyle}
				>{`You have successfully purchased. Please check your mailbox to claim the item.`}</div>
			)}
			{insufficient && (
				<ModalCoppyAddress
				addressWallet={formatAddress(
					account?.address !== undefined
						? account?.address
						: ""
				)}
				onClose={() => setInsufficient(false)} />
			)}
			{showCheckIn && (
				<div
					className={styles.notice1}
				>{`Copied`}</div>
			)}
			{isShowPayment && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setShowPayment(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Confirm Purchase
						</p>
						<p
							className={styles.confirmPurchasedes}
						>{`• Package: + 500 Spin Energy`}</p>
						<div
							style={{
								display: "flex",
								alignItems: "start",
							}}
						>
							<span className={styles.confirmPurchasedes}>
								{`• `}
							</span>
							<span
								className={styles.confirmPurchasedes1}
							>{` Bonus: +400 Spin Energy (x2 for 1st time purchase)`}</span>
						</div>
						<p
							className={styles.confirmPurchasedes}
						>{`• Price: ${dataHeader?.Price.toFixed(
							2
						)}$ (Pay by ETH on BASE) `}</p>
						<div
							style={{
								height: "30px",
							}}
						></div>
						<GameButton
							title="Buy"
							onTap={() => {
								buyItem(dataHeader!.Id, dataHeader!.Price);
							}}
						/>
					</div>
					<LoadingCommon
						visible={isLoading}
						width={80}
						height={80}
						isLeft10={true}
					/>
				</ChoosePlanFarming>
			)}
		</div>
	);
}
