import style from "./skip.module.scss";
import React, { useEffect } from "react";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import aptosLogo from "../../../assets/images/common/aptosLogo.png";
import titleAptos from "../../../assets/images/common/titleAptos.png";
import GameButton from "../../commonButton/gameButton/GameButton";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { aptos, MODULE_ADDRESS } from "../../../aptosConfig";
import { encodeJwt } from "../../../utils/extension";
import { requestSkipBuildingApi } from "../../../services/claimApiService";
import stavaxImg from "../../../assets/images/common/LogoBase.png";
import titlestavaxImg from "../../../assets/images/common/titleSkipBuild.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import { stavaxAccount } from "../../../connectStavax";
import {
	SKIP_BUILDING_CONTRACT_ADDRESS_BASE,
	abiB,
} from "../../../ABI/skipBuilding";
import { parseEther } from "viem";
type ConfirmTransactionModalType = {
	isLeadId: any;
	buildingId: any;
	onClose: () => void;
	noConnect: () => void;
};
export default function ConfirmTransactionModal({
	isLeadId,
	buildingId,
	onClose,
	noConnect,
}: ConfirmTransactionModalType) {
	const { account, signAndSubmitTransaction } = useWallet();
	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			checkSkiped();
		}
	};
	const accountWagmi = useAccount();
	const publicClient = usePublicClient();
	const { switchChainAsync } = useSwitchChain();
	const { writeContractAsync } = useWriteContract();
	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange
			);
		};
	}, []);

	const handleSkipBuilding = async (
		wallet: any,
		buildid: number,
		islandID: number
	) => {
		try {
			const value = {
				Wallet: wallet,
				BuildingId: buildid,
				IslandId: islandID,
			};
			console.log({ value });
			const payLoadData = await encodeJwt(value);
			const res = await requestSkipBuildingApi({ value: payLoadData });
			var iframe: any = document.getElementById("isLandIframe");
			iframe.contentWindow.postMessage({ type: "BUILT", text: "" }, "*");
			onClose();
		} catch (e) {
			console.log(e);
			onClose();
		}
	};
	// const privateKey = new Ed25519PrivateKey(
	// 	process.env.REACT_APP_PRIVATE_KEY!
	// );
	// const feePayer = Account.fromPrivateKey({ privateKey });
	// const skipBuilding = async () => {
	// 	try {
	// 		const transaction = await aptos.transaction.build.simple({
	// 			withFeePayer: true,
	// 			sender: account?.address as any,
	// 			data: {
	// 				function: `${MODULE_ADDRESS}::SlimeSkipBuild::mint`,
	// 				functionArguments: [],
	// 			},
	// 		});
	// 		const senderAuthenticator = await signTransaction(transaction);
	// 		const feePayerAuthenticator = aptos.transaction.signAsFeePayer({
	// 			signer: feePayer,
	// 			transaction,
	// 		});

	// 		const committedTransaction = await aptos.transaction.submit.simple({
	// 			transaction,
	// 			senderAuthenticator: senderAuthenticator,
	// 			feePayerAuthenticator: feePayerAuthenticator,
	// 		});

	// 		const executedTransaction = await aptos.waitForTransaction({
	// 			transactionHash: committedTransaction.hash,
	// 		});
	// 		checkSkiped();
	// 		console.log({ executedTransaction });
	// 	} catch (e) {
	// 		checkSkiped();
	// 	}
	// };
	// SKIP BUILDING
	const skipBuildingStavax = async (buildingId: number, islandId: number) => {
			try {
				const hash = await writeContractAsync({
					abi: abiB,
					address: SKIP_BUILDING_CONTRACT_ADDRESS_BASE,
					functionName: "mint",
					args: [buildingId, islandId],
					value: parseEther("0"),
				});
				console.log({ hash });
				handleSkipBuilding(accountWagmi.address, buildingId, islandId);
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
				var iframe: any = document.getElementById("isLandIframe");
				iframe?.contentWindow?.postMessage(
					{ type: "CHECKEDIN", text: "" },
					"*"
				);
			} catch (e) {}
	};
	const skipBuilding = async (buildingId: number, islandId: number) => {
		if (account?.address === undefined) {
			return noConnect();
		} else {
			try {
				const response = await signAndSubmitTransaction({
					sender: account?.address,
					data: {
						function: `${MODULE_ADDRESS}::SlimeSkipBuild::mint`,
						functionArguments: [],
					},
				});
				try {
					await aptos.waitForTransaction({
						transactionHash: response.hash,
					});
					console.log({ response });
					checkSkiped();
				} catch (error) {
					checkSkiped();
				}
			} catch (e) {
				checkSkiped();
			}
		}
	};

	//  SKIPED
	const checkSkiped = async () => {
		if (!account) return;
		try {
			const isCheckedIn: any = await aptos.view({
				payload: {
					function: `${MODULE_ADDRESS}::SlimeSkipBuild::get_last_checkin_time`,
					typeArguments: [],
					functionArguments: [account.address],
				},
			});
			if (isCheckedIn[0]) {
				const timestamp = isCheckedIn[0] * 1000;
				const date = new Date(timestamp);
				const now = new Date();
				const diffInMinutes =
					(now.getTime() - date.getTime()) / 1000 / 60;
				console.log(Math.abs(diffInMinutes) <= 0.2);
				if (Math.abs(diffInMinutes) <= 0.1) {
					console.log("asdfsdf");
					handleSkipBuilding(account?.address, buildingId, isLeadId);
				}
			} else {
				onClose();
			}
		} catch (e: any) {
			console.log({ e });
		}
	};
	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<img
					src={buttonCancel}
					width={36}
					height={38}
					alt="button cancel"
					className={style.button__close}
					onClick={onClose}
				/>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						height: "100%",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<img src={stavaxImg} alt="aptosLogo" width={90}></img>
					<img
						src={titlestavaxImg}
						alt="aptosLogo"
						style={{
							width: "86%",
							margin: "20px 0px",
							marginBottom: "36px",
						}}
					></img>
					<CircleButton
						title="Approve"
						onTap={() => {
							skipBuildingStavax(buildingId, isLeadId);
						}}
					/>
				</div>
			</div>
			<div className={`${style.modalOverlay}`}></div>
		</div>
	);
}
