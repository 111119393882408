import { isMobile } from "react-device-detect";
import React, { useEffect, useRef, useState } from "react";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";
import ProfileTeleMiniApp from "./components/ProfileTeleMiniApp";
import UnityComponent from "./components/UnityComponent";
import QuestPage, { requestCheckedIn } from "./components/QuestComponent";
import {
	apiPillageJourneyInfo,
	apiRegisterAccountWithAddress,
	requestSkipBuildingApi,
} from "../../../services/claimApiService";
import {
	apiGetMyAccount,
	checkedInRequest,
	requestUpdateProfile,
} from "../../../services/accountApiService";
import { encodeJwt } from "../../../utils/extension";
import { getRandomString, tabData } from "../../../utils/constants/pages";
import { useLocation } from "react-router-dom";
import IsLandComponent from "./components/IsLand";
import { aptos, MODULE_ADDRESS } from "../../../aptosConfig";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import AptosWalletConnect from "./walletConnect";
import ConfirmTransactionModal from "../../../commonsUi/modals/confirmTransaction/confirmPopUp";
import ConfirmCheckinModal from "../../../commonsUi/modals/confirmCheckin";
import CardPage from "./components/Card/CardPage";
import TeleEnergyShop from "./components/teleEnergyShop/TeleEnergyShop";
import { stavaxAccount } from "../../../connectStavax";
import AccountPage from "./components/Account";
import ConnectWalletModal from "../../../commonsUi/modals/reachUstd";
import RankingPage from "./components/ranking/RankingPage";
import { useAccount } from "wagmi";
interface SkipBuildingData {
	buildingId?: string;
	islandId?: string;
}

interface TelegramWebApp {
	ready: () => void;
	expand: () => void;
}
// Declare the Telegram WebApp interface
interface TelegramWebApp {
	initData: string;
	initDataUnsafe: {
		user: {
			id: number;
			first_name: string;
			last_name?: string;
			username?: string;
			language_code?: string;
			photo_url?: string;
		};
		[key: string]: any;
	};
}

interface Window {
	Telegram: {
		WebApp: TelegramWebApp;
	};
}

declare global {
	interface Window {
		Telegram?: {
			WebApp?: TelegramWebApp;
		};
	}
}
type TeleAppPageType = {
	walletType: any;
};
const TeleAppPage: React.FC<TeleAppPageType> = ({ walletType }) => {
	const [screen, setScreen] = useState(1);
	const [indexTab, setIndexTab] = useState(2);
	const [isUIVisible, setIsUIVisible] = useState(false);
	const [dataRenderHeader, setDataRenderHeader] = useState();
	const [isJoinPillage, setIsJoinPillage] = useState(false);
	const [isClient, setIsClient] = useState(false);
	const [isLoadingFirst, setIsLoadingFirst] = useState(false);
	const [deviceIsMobile, setDeviceIsMobile] = useState(false);
	const [previousShareEvent, setPreviousShareEvent] = useState(null);
	const [userIdTele, setUserIdTele] = useState<string | string[] | undefined>(
		undefined
	);
	const [isShowConnectWalelt, setShowConnectWallet] = useState(true);
	const shareRef = useRef<HTMLParagraphElement>(null);
	const [isCheckedIn, setCheckIn] = useState<string | null>(null);
	const [isClaim, setClaim] = useState(false);
	const location = useLocation();
	const skipBuildingHandled = useRef(false);
	const handledEvents = useRef(new Set());
	const [isSkipBuilding, setSkipBuilding] = useState<SkipBuildingData>({});
	const [isShowCheckin, setShowCheckin] = useState(false);
	const [isShowConnectWallet, setConnectWallet] = useState(false);
	const account = useAccount();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const userId: any = params.get("user_id");
		const referId = params.get("refer_id");
		const userName = params.get("user_name");
		setUserIdTele(userId);
		localStorage.setItem("_userId", userId);
		handleLoginWhenWelcome(userId, referId, userName);
	}, []);

	const handleShare = async () => {
		const shareLink = `https://t.me/Slime_Revolution_bot?start=${userIdTele}`;
		if (navigator.share) {
			try {
				await navigator.share({
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: shareLink,
				});
			} catch (error) {}
		}
	};

	const copyTextToClipboard = (text: string) => {
		var textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			var successful = document.execCommand("copy");
			var msg = successful ? "successful" : "unsuccessful";
		} catch (err) {
			console.error("Oops, unable to copy", err);
		}
		document.body.removeChild(textArea);
	};
	console.log({ account });
	useEffect(() => {
		const handleMessage = (event: any) => {
			const eventType = event.data.type;
			if (!handledEvents.current.has(eventType)) {
				handledEvents.current.add(eventType);
				switch (eventType) {
					case "INVITE":
						if (shareRef.current) {
							shareRef.current.click();
						}
						break;
					case "SKIP_BUILDING":
						setSkipBuilding({
							buildingId: event.data.data.buildingId,
							islandId: event.data.data.islandId,
						});
						// skipBuilding(
						// 	event.data.data.buildingId,
						// 	event.data.data.islandId
						// );
						break;
					case "CHECKIN":
						setShowCheckin(true);
						break;

					case "CONNECT_WALLET":
						setIndexTab(4);
						break;

					case "CHANGE_TAB":
						const index = event.data.index;
						setIndexTab(index);
						break;

					case "COPY_TO_CLIPBOARD":
						copyTextToClipboard(
							`https://t.me/Slime_Revolution_bot?start=${userIdTele}`
						);
						break;
					default:
						break;
				}

				setTimeout(() => {
					handledEvents.current.delete(eventType);
				}, 1000);
			}
		};

		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	useEffect(() => {
		if (window.Telegram && window.Telegram.WebApp) {
			const initData = window.Telegram.WebApp.initData;
			const initDataParsed = new URLSearchParams(initData);
			const userString = initDataParsed.get("user");
			if (userString) {
				const user = JSON.parse(userString);
				console.log(user);
				localStorage.setItem("user_telegramaa", JSON.stringify(user));
				setUserIdTele(user.id);
				localStorage.setItem("_userId", user.id);
				handleLoginWhenWelcome(user.id, 0, user.username);
			}
		}
	}, []);

	useEffect(() => {
		if (account?.address !== undefined) {
			requestUpdateProfile(account.address);
		}
	}, [account]);

	const handleCallApiPillageJourneyInfoAgain = async () => {
		const responsePillageJourneyInfo: any = await apiPillageJourneyInfo();
		if (
			responsePillageJourneyInfo?.success &&
			responsePillageJourneyInfo?.data?.is_join_pillage === true
		) {
			localStorage.setItem(
				"journey_info",
				JSON.stringify(responsePillageJourneyInfo?.data)
			);
			setIsLoadingFirst(false);
			setScreen(3);
			setIndexTab(2);
			setIsJoinPillage(true);
		} else {
			setIsLoadingFirst(false);
		}
	};

	const handleCallApiInFoUser = async () => {
		const responseInfoFarming = await apiGetMyAccount();
		if (responseInfoFarming) {
			localStorage.setItem(
				"info_data_user",
				JSON.stringify(responseInfoFarming?.data)
			);
		}
	};

	const handleLoginWhenWelcome = async (
		userId: number | string,
		referId: any,
		userName: any
	) => {
		localStorage.removeItem("dataInfoFarming");
		localStorage.removeItem("info_data");
		localStorage.removeItem("journey_info");
		localStorage.removeItem("info_data_user");
		const valueAddress = {
			WalletAddress: account?.address,
			WalletType:
				walletType !== undefined
					? walletType
					: localStorage.getItem("wallet_type"),
		};
		console.log({ valueAddress });
		const payLoadData = await encodeJwt(valueAddress);
		const responseRegisterAccount: any =
			await apiRegisterAccountWithAddress({
				value: payLoadData,
			});
		if (responseRegisterAccount?.success) {
			localStorage.setItem(
				"_gameAccessToken",
				responseRegisterAccount?.data?.access_token
			);
			localStorage.setItem("_AddressAccount", payLoadData);
			localStorage.setItem(
				"info_data",
				JSON.stringify(responseRegisterAccount?.data)
			);
			handleCallApiPillageJourneyInfoAgain();
			handleCallApiInFoUser();
			setIsUIVisible(true);
			setIndexTab(2);
			setTimeout(() => {
				setShowConnectWallet(true);
			}, 2000);
		}
	};

	useEffect(() => {
		if (isMobile) {
			setDeviceIsMobile(true);
		} else {
			setDeviceIsMobile(false);
		}
	}, []);

	useEffect(() => {
		if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
			window.Telegram.WebApp.ready();
			handleExpand();
		}
	}, []);

	const handleExpand = () => {
		if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
			window.Telegram.WebApp.expand();
		}
	};

	function getQueryParams() {
		const params = new URLSearchParams(window.location.search);
		const queryParams: { [key: string]: string } = {};
		params.forEach((value, key) => {
			queryParams[key] = value;
		});
		return queryParams;
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setCheckIn(null);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isCheckedIn]);

	useEffect(() => {
		const queryParams = getQueryParams();
	}, []);

	return (
		<div className={styles.main1}>
			{Object.keys(isSkipBuilding).length > 0 && (
				<ConfirmTransactionModal
					noConnect={() => {
						setSkipBuilding({});
						setTimeout(() => {
							setShowConnectWallet(true);
						}, 200);
					}}
					onClose={() => setSkipBuilding({})}
					isLeadId={isSkipBuilding?.islandId}
					buildingId={isSkipBuilding?.buildingId}
				/>
			)}

			{isShowCheckin && (
				<ConfirmCheckinModal
					noConnect={() => {
						setSkipBuilding({});
						setTimeout(() => {
							setShowConnectWallet(true);
						}, 200);
					}}
					onClose={() => setShowCheckin(false)}
					statusCheckin={(value) => {
						if (value) {
							setClaim(true);
							setCheckIn("Check in successfully");
						} else {
							setCheckIn("Check in failure");
						}
					}}
				/>
			)}
			{isShowConnectWallet && (
				<ConnectWalletModal onClose={() => setConnectWallet(false)} />
			)}
			<div className={`${styles.main} `}>
				<RWebShare
					data={{
						title: "Slime Revolution",
						text: "Play Slime Revolution with me!",
						url: `https://t.me/Slime_Revolution_bot?start=${userIdTele}`,
					}}
					onClick={() => console.log("shared successfully!")}
				>
					<p ref={shareRef}></p>
				</RWebShare>
				{isUIVisible && !isLoadingFirst ? (
					<div>
						{tabData.map((item, index) => {
							if (indexTab === item?.id) {
								return (
									<div key={index}>
										{item?.renderComponent}
									</div>
								);
							}
						})}
						{/* <div
							style={{
								display: indexTab === 2 ? "block" : "none",
							}}
						>
							<UnityComponent
								visible={indexTab === 1}
								setIsClient={setIsClient}
							/>
						</div> */}
						{indexTab === 3 && <TeleEnergyShop />}
						{indexTab === 4 && <AccountPage />}
						{indexTab === 1 && <RankingPage />}
						{indexTab === 0 && <QuestPage />}
						<div
							style={{
								display: indexTab === 2 ? "block" : "none",
							}}
						>
							<IsLandComponent
								visible={indexTab === 2}
								setIsClient={setIsClient}
							/>
						</div>
						{/* <div
							style={{
								display: indexTab === 0 ? "block" : "none",
							}}
						>
							<QuestPage
							/>
						</div> */}
						{isCheckedIn != null && (
							<div
								className={styles.checkedInStyle}
							>{`${isCheckedIn}`}</div>
						)}
					</div>
				) : (
					<div></div>
				)}

				<div>
					<>
						<div className={styles.shadowBottom}></div>
						<div className={styles.bottomNavigation}>
							{tabData.map((e, index) => (
								<div
									key={index}
									onClick={() => {
										setIndexTab(index);
									}}
									id="button"
								>
									<img
										src={
											index === indexTab
												? e.imgActive
												: e.imgInActive
										}
										alt={`${e.imgActive}`}
										height={50}
									/>
								</div>
							))}
						</div>
					</>
				</div>
			</div>
		</div>
	);
};

export default TeleAppPage;
