import React from "react";
import styles from "./index.module.scss";
interface IButtonRectangle {
	text?: string;
	color?: string;
	styleCustom?: {};
	classCssText: string | "";
	classCssButton: string | "";
	onClickButton?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export default function ButtonRectangle({
	text,
	color,
	styleCustom,
	onClickButton,
	classCssText = "",
	classCssButton = "",
}: IButtonRectangle) {
	return (
		<div
			className={`${styles.button__wrap} ${styles[classCssButton]}`}
			style={{ ...styleCustom, color }}
			onClick={onClickButton}
		>
			<p className={styles[classCssText]}>{text}</p>
		</div>
	);
}
