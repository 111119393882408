/** @format */

import React, { useEffect, useState } from "react";
import styles from "./teleEnergyStyle.module.scss";
import spcImg from "../../../../../assets/images/teleImage/usdtImg.png";
import DiscountImag from "../../../../../assets/images/teleImage/waterDiscount.png";
import Slime from "../../../../../assets/roll/Slime.svg";
import { parseEther } from "viem";
import {
	useAccount,
	useBalance,
	usePublicClient,
	useSendTransaction,
	useWriteContract,
} from "wagmi";
import { useTransactionReceipt } from "wagmi";
import CommonPriceButton from "../../../../../commonsUi/CommonPriceButton";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import GameButton from "../../../../../commonsUi/commonButton/gameButton/GameButton";
import LoadingCommon from "../../../../../commonsUi/CommonUI/LoadingApp";
import {
	ShopItemModel,
	ShopModel,
} from "../../../../../commonsUi/modals/shop/shopModel";
import { stavaxAccount } from "../../../../../connectStavax";
import {
	SHOP_WALLET_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../../../ABI/shopWallet";
import { writeClipboardText } from "../../../../../utils/function/text";
import { formatAddress } from "../Account";
import ModalCoppyAddress from "../../../../../commonsUi/modals/ModalCopyAddress";

type ShopItemPageType = {
	dataShop: ShopItemModel[];
};

export default function ShopItemPage({ dataShop }: ShopItemPageType) {
	const [isLoading, setLoading] = useState(false);
	const [paymentStatus, setPaymentStatus] = useState(false);
	const [dataSelected, setDataSelected] = useState<ShopItemModel | null>(
		null
	);
	const [ethToUSDT, setEthToUsdt] = useState(0);
	const [indexSelected, setIndexSelected] = useState(0);
	const { sendTransaction, sendTransactionAsync } = useSendTransaction();
	const [isBought, setBought] = useState(false);
	const account = useAccount();
	const { writeContractAsync } = useWriteContract();
	const publicClient = usePublicClient();
	const [insufficient, setInsufficient] = useState(false);
	const [showCheckIn, setShowCheckIn] = useState(false);
	const handleTransfer = async (id: number, amount: string) => {
		if (account?.address === undefined) {
			const session = await stavaxAccount.connect();
		} else {
			try {
				stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: SHOP_WALLET_CONTRACT_ADDRESS_BASE,
					functionName: "deposit",
					value: parseEther(amount),
					args: [id],
				});
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
				setDataSelected(null);
				setBought(true);
			} catch (e) {
				console.log({ e });
			}
		}
	};
	const ethBalance = useBalance({
		address: account?.address,
	});
	useEffect(() => {
		const timer = setTimeout(() => {
			setBought(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isBought]);

	const buyItem = async (id: number, usdtPrice: number) => {
		try {
			const bnbUsdtPriceResponse = await fetch(
				"https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT"
			);
			const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
			const value =
				Number(ethBalance?.data?.formatted) * bnbUsdtPriceData.price;
			if (value > usdtPrice) {
				handleTransfer(
					id,
					(usdtPrice / bnbUsdtPriceData.price).toString()
				);
			} else {
				setDataSelected(null);
				setInsufficient(true);
			}
		} catch (error) {
			console.error("Error fetching BNB/USDT price:", error);
		}
	};

	const BuildLevelWidget = () => {
		return (
			<div className={styles.bodyLevel}>
				<img src={DiscountImag} alt="DiscountImag" height={30} />
				<p className={styles.levelStyle}>x2</p>
			</div>
		);
	};

	const getStyle = (index: number) => {
		switch (index) {
			case 0:
				return styles.cardItemStyle;
			case 1:
				return styles.cardItemStyle1;
			case 2:
				return styles.cardItemStyle2;
			case 3:
				return styles.cardItemStyle3;
			case 4:
				return styles.cardItemStyle4;
			case 5:
				return styles.cardItemStyle5;
			default:
				return styles.cardItemStyle3;
		}
	};
	const getTotalEnergy = (index: number) => {
		switch (index) {
			case 0:
				return 10;
			case 1:
				return 50;
			case 2:
				return 100;
			case 3:
				return 200;
			case 4:
				return 300;
			case 5:
				return 500;
			default:
				return 500;
		}
	};
	const payment = async () => {
		setLoading(true);
		try {
			const transactionHash = await sendTransactionAsync({
				to: "0x1041298850832D431c11dCd01dBE91e9AC8A4c5e",
				value: parseEther("0.01"),
			});
			setLoading(false);
			setPaymentStatus(true);
			console.log({ transactionHash });
		} catch (e) {
			setLoading(false);
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);
	return (
		<div className={styles.container}>
			<div className={styles.poolReward}>
				<span>200%</span> more for first purchase
			</div>

			{dataShop &&
				dataShop!.map((item, index) => (
					<div key={index} className={styles.cardBodyStyle}>
						<div className={getStyle(index)}>
							<p className={styles.itemEnergyStyle}>
								{`${item.PackageName}`}
							</p>
						</div>
						<div className={styles.cardParent}>
							{item.BuyFirst && <BuildLevelWidget />}
						</div>
						<div className={styles.buyButtonStyle}>
							<CommonPriceButton
								onClick={() => {
									setDataSelected(item);
									setIndexSelected(index);
								}}
							>
								<span className={styles.priceStyle}>{`${
									item.BuyFirst
										? item.Price * item.SaleOff
										: item.Price
								}$`}</span>
							</CommonPriceButton>
						</div>
					</div>
				))}

			{dataSelected != null && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setDataSelected(null)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Confirm Purchase
						</p>
						<p
							className={styles.confirmPurchasedes}
						>{`• Package: +${getTotalEnergy(
							indexSelected
						)} Spin Energy`}</p>
						<div
							style={{
								display: "flex",
								alignItems: "start",
							}}
						>
							<span className={styles.confirmPurchasedes}>
								{`•`}&nbsp;
							</span>
							<span
								className={styles.confirmPurchasedes}
							>{`Bonus: +${getTotalEnergy(
								indexSelected
							)} Spin Energy (x2 for 1st time purchase)`}</span>
						</div>
						<p
							className={styles.confirmPurchasedes}
						>{`• Price: ${`${
							dataSelected.BuyFirst
								? dataSelected.Price * dataSelected.SaleOff
								: dataSelected.Price
						}`}$ (Pay by ETH on BASE) `}</p>
						<div
							style={{
								height: "30px",
							}}
						></div>
						<GameButton
							title="Buy"
							onTap={() => {
								buyItem(
									dataSelected!.Id,
									dataSelected.BuyFirst
										? dataSelected.Price *
												dataSelected.SaleOff
										: dataSelected.Price
								);
							}}
						/>
					</div>
					<LoadingCommon
						visible={isLoading}
						width={80}
						height={80}
						isLeft10={true}
					/>
				</ChoosePlanFarming>
			)}

			{isBought && (
				<div
					className={styles.checkedInStyle}
				>{`You have successfully purchased. Please check your mailbox to claim the item.`}</div>
			)}
			{insufficient && (
				<ModalCoppyAddress
					addressWallet={formatAddress(
						account?.address !== undefined ? account?.address : ""
					)}
					onClose={() => setInsufficient(false)}
				/>
			)}
			{showCheckIn && <div className={styles.notice1}>{`Copied`}</div>}
			{paymentStatus && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setPaymentStatus(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Purchase Successful
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: "80vw",
								justifyContent: "center",
							}}
						>
							<p className={styles.confirmPurchase}>
								You Spin Energy has been sent to your Mailbox
							</p>
						</div>
						<GameButton
							title="Claim now"
							onTap={() => {
								setPaymentStatus(false);
								setDataSelected(null);
							}}
						/>
					</div>
				</ChoosePlanFarming>
			)}
		</div>
	);
}
